import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Header from "../Header";
import c1 from "../../assets/c1.png";
import c2 from "../../assets/c2.png";
import c3 from "../../assets/c3.png";
import KnowMore from "./KnowMore";
import Footer from "../Footer";
import homeAbout from "../../assets/home-about.png";
import ei1 from "../../assets/ei1.png";
import ei2 from "../../assets/ei2.png";
import ei3 from "../../assets/ei3.png";
import ei4 from "../../assets/ei4.png";
import ei5 from "../../assets/ei5.png";
import ei6 from "../../assets/ei6.png";
import { Parallax, Background } from "react-parallax";
import homeImg from "../../assets/home.png";
import codeImg from "../../assets/code.png";

const Home = () => {
  const [isCard, setCard] = useState(false);
  const [isCardValue, setCardValue] = useState(0);

  const homeCardOver = (i) => {
    setCard(true);
    setCardValue(i);
  };
  const homeCardLeave = (i) => {
    setCard(false);
    setCardValue(i);
  };

  return (
    <>
      <Header />
      <div className="header-marg">
        <Parallax strength={500} style={{ height: "100vh" }}>
          <Background className="enterprise-bg home-bg">
            <Row className="mx-0  padding-side">
              <Col xs={12} lg={12}>
                <div className="text-center ">
                  <li className="ft-2" style={{ color: "#fff" }}>
                    Empowering you with the
                  </li>
                  <li
                    className="main-title mt-2"
                    style={{ lineHeight: "3.5rem", color: "#FFB119" }}
                  >
                    digital transformation
                  </li>
                </div>
              </Col>
            </Row>
          </Background>
        </Parallax>

        <div className="home-line-box only-web">
          <div className="home-line home-line1"></div>
          <div className="home-line home-line2"></div>
          <div className="home-line home-line3"></div>
          <div className="home-line home-line4"></div>
          <div className="home-line home-line5"></div>
          <div className="home-line home-line6"></div>
        </div>

        <Row className="mx-0 padding-side mt-5">
          <Col xs={12} lg={4} className="mt-4">
            <div
              className="home-card"
              onMouseOver={() => homeCardOver(1)}
              onMouseLeave={() => homeCardLeave(1)}
            >
              <div className="home-card-mark"></div>
              <div>
                <img
                  src={c2}
                  className={
                    isCard === true && isCardValue === 1
                      ? "home-card-img2"
                      : "home-card-img"
                  }
                  alt=""
                />
                <li
                  className="my-3"
                  style={{ fontSize: "1.5rem", fontWeight: "600" }}
                >
                  Enterprise
                </li>
                <li>Solution first</li>
              </div>
            </div>
          </Col>
          <Col xs={12} lg={4} className="mt-4">
            <div
              className="home-card"
              onMouseOver={() => homeCardOver(2)}
              onMouseLeave={() => homeCardLeave(2)}
            >
              <div className="home-card-mark"></div>
              <div>
                <img
                  src={c3}
                  className={
                    isCard === true && isCardValue === 2
                      ? "home-card-img2"
                      : "home-card-img"
                  }
                  alt=""
                />
                <li
                  className="my-3"
                  style={{ fontSize: "1.5rem", fontWeight: "600" }}
                >
                  Startups
                </li>
                <li>Bridging skill-gap</li>
              </div>
            </div>
          </Col>
          <Col xs={12} lg={4} className="mt-4">
            <div
              className="home-card"
              onMouseOver={() => homeCardOver(3)}
              onMouseLeave={() => homeCardLeave(3)}
            >
              <div className="home-card-mark"></div>
              <div>
                <img
                  src={c1}
                  className={
                    isCard === true && isCardValue === 3
                      ? "home-card-img2"
                      : "home-card-img"
                  }
                  alt=""
                />
                <li
                  className="my-3"
                  style={{ fontSize: "1.5rem", fontWeight: "600" }}
                >
                  Community
                </li>
                <li>Let's innovate!</li>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="mx-0 mt-5">
          <Col xs={12} lg={6} className="px-0">
            <img src={homeAbout} width="100%" height="100%" alt="" />
          </Col>
          <Col xs={12} lg={6} className="px-0">
            <div className=" home-about-bg">
              <div style={{ maxWidth: "400px" }}>
                <li className="sub-title" style={{ color: "#fff" }}>
                  About
                </li>
                <li
                  className="main-title "
                  style={{ color: "#FFB119", lineHeight: "2.5rem" }}
                >
                  Organisation
                </li>
                <li className="mt-4" style={{ color: "#fff" }}>
                  Welcome to Instilytics Consulting LLP
                </li>
                <li className="mt-2" style={{ color: "#fff" }}>
                  Our dedicated team of professionals have been knowledge
                  experts in many digital transformations across the globe and
                  are trusted by some of the largest organizations.
                </li>
                <li className="mt-2" style={{ color: "#fff" }}>
                  At Instilytics, we are solution-centric and we strongly
                  believe that there are infinite possibilities with technology.
                  Lets build the future!
                </li>
              </div>
            </div>
          </Col>
        </Row>

        <Row className="mx-0 mt-5 padding-side">
          <li className="partner-card-header mt-4">Solution offered</li>
          <Col xs={12} lg={4}>
            <div
              className="partner-card mt-4"
              onMouseOver={() => homeCardOver(4)}
              onMouseLeave={() => homeCardLeave(4)}
            >
              <div>
                <img
                  src={ei1}
                  className={
                    isCard === true && isCardValue === 4
                      ? "home-sol-card-img2"
                      : "home-sol-card-img"
                  }
                  alt=""
                />
                <li
                  className="mt-2"
                  style={{ fontWeight: "600", fontSize: "1.2rem" }}
                >
                  Product Innovation
                </li>
              </div>
            </div>
          </Col>
          <Col xs={12} lg={4}>
            <div
              className="partner-card mt-4"
              onMouseOver={() => homeCardOver(5)}
              onMouseLeave={() => homeCardLeave(5)}
            >
              <div>
                <img
                  src={ei2}
                  className={
                    isCard === true && isCardValue === 5
                      ? "home-sol-card-img2"
                      : "home-sol-card-img"
                  }
                  alt=""
                />
                <li
                  className="mt-2"
                  style={{ fontWeight: "600", fontSize: "1.2rem" }}
                >
                  Data & Intelligence Consulting
                </li>
              </div>
            </div>
          </Col>
          <Col xs={12} lg={4}>
            <div
              className="partner-card mt-4"
              onMouseOver={() => homeCardOver(6)}
              onMouseLeave={() => homeCardLeave(6)}
            >
              <div>
                <img
                  src={ei3}
                  className={
                    isCard === true && isCardValue === 6
                      ? "home-sol-card-img2"
                      : "home-sol-card-img"
                  }
                  alt=""
                />
                <li
                  className="mt-2"
                  style={{ fontWeight: "600", fontSize: "1.2rem" }}
                >
                  Conversational Intelligence & Analytics
                </li>
              </div>
            </div>
          </Col>
          <Col xs={12} lg={4}>
            <div
              className="partner-card mt-4"
              onMouseOver={() => homeCardOver(7)}
              onMouseLeave={() => homeCardLeave(7)}
            >
              <div>
                <img
                  src={ei4}
                  className={
                    isCard === true && isCardValue === 7
                      ? "home-sol-card-img2"
                      : "home-sol-card-img"
                  }
                  alt=""
                />
                <li
                  className="mt-2"
                  style={{ fontWeight: "600", fontSize: "1.2rem" }}
                >
                  Enterprise IT Automations
                </li>
              </div>
            </div>
          </Col>
          <Col xs={12} lg={4}>
            <div
              className="partner-card mt-4"
              onMouseOver={() => homeCardOver(8)}
              onMouseLeave={() => homeCardLeave(8)}
            >
              <div>
                <img
                  src={ei5}
                  className={
                    isCard === true && isCardValue === 8
                      ? "home-sol-card-img2"
                      : "home-sol-card-img"
                  }
                  alt=""
                />
                <li
                  className="mt-2"
                  style={{ fontWeight: "600", fontSize: "1.2rem" }}
                >
                  Cloud Consultation & Management
                </li>
              </div>
            </div>
          </Col>
          <Col xs={12} lg={4}>
            <div
              className="partner-card mt-4"
              onMouseOver={() => homeCardOver(9)}
              onMouseLeave={() => homeCardLeave(9)}
            >
              <div>
                <img
                  src={ei6}
                  className={
                    isCard === true && isCardValue === 9
                      ? "home-sol-card-img2"
                      : "home-sol-card-img"
                  }
                  alt=""
                />
                <li
                  className="mt-2"
                  style={{ fontWeight: "600", fontSize: "1.2rem" }}
                >
                  Digital security
                </li>
              </div>
            </div>
          </Col>
        </Row>

        <Parallax strength={100} className="mt-5" style={{ height: "300px" }}>
          <Background className="code-bg">
            <div className=" padding-side">
              <div className="text-center">
                <li
                  style={{
                    color: "#FFB119",
                    fontSize: "3rem",
                    fontWeight: "700",
                  }}
                >
                  Why Us?
                </li>
                <li
                  style={{
                    color: "#fff",
                    marginTop: "1rem",
                    fontSize: "1.2rem",
                  }}
                >
                  We strive to be the best in the breed of solution providers
                  and are passionate about what we do and impact we have with
                  our clients and community.
                </li>
              </div>
            </div>
          </Background>
        </Parallax>

        {/* <Row className="mx-0 padding-side mt-5">
          <li
            className="text-center"
            style={{ fontWeight: "700", fontSize: "3rem" }}
          >
            Testimonials
          </li>
          <Col xs={12} lg={6}>
            <div className="home-card home-card2 mt-4">
              <div className="home-card-mark"></div>
              <div>
                <li className="google-tx">Google</li>
                <li className="mt-3 text-center" style={{ color: "#373F41" }}>
                  Est tation latine aliquip id, mea ad tale illud definitiones.
                  Periculis omittantur necessitatibus eum ad, pro eripuit
                  minimum comprehensam ne, usu cu stet eum ad
                </li>
                <li className="text-center mt-3" style={{ color: "#737B7D" }}>
                  Connie Robertson
                </li>
              </div>
            </div>
          </Col>
          <Col xs={12} lg={6}>
            <div className="home-card home-card2 mt-4">
              <div className="home-card-mark"></div>
              <div>
                <li className="google-tx">Google</li>
                <li className="mt-3 text-center" style={{ color: "#373F41" }}>
                  Est tation latine aliquip id, mea ad tale illud definitiones.
                  Periculis omittantur necessitatibus eum ad, pro eripuit
                  minimum comprehensam ne, usu cu stet eum ad
                </li>
                <li className="text-center mt-3" style={{ color: "#737B7D" }}>
                  Connie Robertson
                </li>
              </div>
            </div>
          </Col>
        </Row> */}

        <div className="home-career-bg">
          <Row className="mx-0 padding-side  py-5">
            <Col xs={12} lg={6}>
              <div className="d-flex align-items-center">
                <div className="mt-5 px-2">
                  <li
                    style={{
                      fontSize: "3rem",
                      fontWeight: "700",
                      color: "#3F3D56",
                    }}
                  >
                    Careers
                  </li>
                  <li
                    style={{
                      color: "#3F3D56",
                      maxWidth: "500px",
                      fontSize: "1.2rem",
                    }}
                  >
                    Obsessed with what’s next? Let's explore.
                  </li>
                  <li
                    style={{
                      color: "#3F3D56",
                      maxWidth: "500px",
                      fontSize: "1.2rem",
                    }}
                  >
                    Please share your passion in terms of career. Im passionate
                    about.....
                  </li>
                </div>
              </div>
            </Col>
            <Col xs={12} lg={6}>
              <form
                action="https://docs.google.com/forms/d/e/1FAIpQLSc4InhpE0O5-ybrTZEFymGeRmzsT6kaeLEezUFRBlKejpcT7A/formResponse"
                method="POST"
              >
                <div className="mt-2">
                  <input
                    type="text"
                    name="entry.360293539"
                    placeholder="Full name"
                    className="input w-100"
                  />
                </div>
                <div className="mt-2">
                  <input
                    type="text"
                    name="entry.642870840"
                    placeholder="Email ID"
                    className="input w-100"
                  />
                </div>
                <div className="mt-2">
                  <input
                    type="text"
                    name="entry.1546054113"
                    placeholder="Phone number"
                    className="input w-100"
                  />
                </div>
                <div className="mt-2">
                  <textarea
                    className="input w-100"
                    name="entry.591617690"
                    style={{ height: "100px" }}
                    placeholder="How can we help you?"
                  ></textarea>
                </div>
                <div className="text-center mt-4">
                  <button className="submit-btn2">Submit</button>
                </div>
              </form>
            </Col>
          </Row>
        </div>

        <div id="home-contact">
          <KnowMore name="To know more" />
        </div>

        <div className="padding-side">
          <div
            style={{
              width: "100%",
              height: "2px",
              backgroundColor: "#FBFBFB",
            }}
          ></div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Home;
