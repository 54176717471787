import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import logo from "../assets/logo.png";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { makeStyles } from "@material-ui/core/styles";
import { Menu } from "@material-ui/icons";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  list: {
    width: 300,
  },
  fullList: {
    width: "auto",
  },
});

const Header = (props) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false,
  });

  const [enterprise, setEnterprise] = useState(true);
  const [startup, setStartup] = useState(true);
  const [product, setProduct] = useState(true);
  const [isHistory, setHistory] = useState("");
  const history = useHistory();

  useEffect(() => {
    setHistory(history.location.pathname);
  }, []);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={
        (classes.list,
        {
          [classes.fullList]: anchor === "top" || anchor === "bottom",
        })
      }
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List className="menu-list-mob ">
        <ListItem
          component={NavLink}
          activeStyle={{ color: "#FE8C2F", padding: "12px 0" }}
          to="/enterprise"
          style={{ padding: "12px 0" }}
        >
          <li>ENTERPRISE</li>
        </ListItem>
        <ListItem
          component={NavLink}
          activeStyle={{ color: "#FE8C2F", padding: "12px 0" }}
          to="/startup"
          style={{ padding: "12px 0" }}
        >
          <li>STARTUP</li>
        </ListItem>
        <ListItem
          component={NavLink}
          activeStyle={{ color: "#FE8C2F", padding: "12px 0" }}
          to="/product"
          style={{ padding: "12px 0" }}
        >
          <li>PRODUCTS</li>
        </ListItem>
        {/* <ListItem
          component={NavLink}
          activeStyle={{ color: "#FE8C2F", padding: "12px 0" }}
          to="/career"
          style={{ padding: "12px 0" }}
        >
          <li>CAREER</li>
        </ListItem> */}
        {/* <ListItem
          component={NavLink}
          activeStyle={{ color: "#FE8C2F", padding: "12px 0" }}
          to="/contact"
          style={{ padding: "12px 0" }}
        >
          <li>CONTACT US</li>
        </ListItem> */}
      </List>
    </div>
  );

  return (
    <>
      <div className="header padding-side">
        <Row>
          <Col>
            <a href="/">
              <img src={logo} className="logo" alt="" />
            </a>
          </Col>
          <Col className="only-web">
            <div className="header-items ">
              <a href="/enterprise">
                <li
                  style={
                    isHistory === "/enterprise"
                      ? { color: "#ffb119" }
                      : { color: "#000" }
                  }
                >
                  ENTERPRISE
                </li>
              </a>
              <a href="/startup">
                <li
                  style={
                    isHistory === "/startup"
                      ? { color: "#ffb119" }
                      : { color: "#000" }
                  }
                >
                  STARTUP
                </li>
              </a>
              <a href="/product">
                <li
                  style={
                    isHistory === "/product"
                      ? { color: "#ffb119" }
                      : { color: "#000" }
                  }
                >
                  PRODUCTS
                </li>
              </a>
              {/* <a href="/career">
                <li>CAREERS</li>
              </a> */}
              <a href="/#home-contact">
                <li
                  style={{
                    backgroundColor: "#ffb119",
                    padding: "10px 12px",
                    borderRadius: "10px",
                    boxShadow: "0 2px 4px  rgba(0, 0, 0, 0.2)",
                  }}
                >
                  CONTACT US
                </li>
              </a>
            </div>
          </Col>
        </Row>
        <div className="only-mob">
          {["left"].map((anchor) => (
            <React.Fragment key={anchor}>
              <div
                style={{ margin: "0 12px 0 4px" }}
                onClick={toggleDrawer(anchor, true)}
              >
                <Menu
                  style={{
                    position: "fixed",
                    right: "15px",
                    marginTop: "-50px",
                  }}
                />
              </div>
              <SwipeableDrawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                onOpen={toggleDrawer(anchor, true)}
              >
                {list(anchor)}
              </SwipeableDrawer>
            </React.Fragment>
          ))}
        </div>
      </div>
    </>
  );
};

export default Header;
