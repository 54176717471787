import React from "react";
import Footer from "../Footer";
import Header from "../Header";
import KnowMore from "../home/KnowMore";

const Career = () => {
  return (
    <>
      <Header />
      <div className="header-marg">
        <div className="enterprise-bg career-bg padding-side">
          <li className="enterprise text-center" style={{ color: "#fff" }}>
            CAREER
          </li>
          <div className="bubble bubble1"></div>
          <div className="bubble bubble2"></div>
          <div className="bubble bubble3"></div>
        </div>
        <KnowMore />
      </div>
      <Footer />
    </>
  );
};

export default Career;
