import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Header from "../Header";
import s1 from "../../assets/s1.png";
import s2 from "../../assets/s2.png";
import s3 from "../../assets/s3.png";
import Footer from "../Footer";
import KnowMore from "../home/KnowMore";
import { Parallax, Background } from "react-parallax";

const Startup = () => {
  return (
    <>
      <Header />
      <div className="header-marg">
        <Parallax strength={500} style={{ height: "100vh" }}>
          <Background className="enterprise-bg startup-bg">
            <div className=" padding-side">
              <li className="enterprise text-center" style={{ color: "#fff" }}>
                BRINGING BIG BUSINESS EXPERIENCE TO STARTUPS
              </li>
              <div className="bubble bubble4"></div>
              <div className="bubble bubble5"></div>
              <div className="bubble bubble6"></div>
            </div>
          </Background>
        </Parallax>

        <div className="padding-side mt-5">
          <li
            className="padding-side text-center"
            style={{ fontSize: "1.2rem" }}
          >
            Our build for equity program gets your idea off the ground and into
            the market
          </li>
        </div>

        <Row className="mx-0  mt-5">
          <Col xs={12} lg={6} className="px-0 mt-4">
            <div className=" py-4">
              <img
                src={s1}
                style={{
                  width: "100%",
                  height: "350px",
                  zIndex: 1,
                  position: "relative",
                }}
                alt=""
              />
            </div>
          </Col>
          <Col xs={12} lg={6} className="px-0 mt-4">
            <div className="padding-side py-5">
              <li className="mt-4" style={{ fontSize: "2rem" }}>
                How does
              </li>
              <li
                className="sub-title "
                style={{
                  color: "#3152B5",
                  fontSize: "3rem",
                  lineHeight: "2.4rem",
                }}
              >
                it work?
              </li>
              <li className="mt-4">
                As a tech startup founder, your first concern is to find the
                right software development team that could build your product.
                Many times the bill on software development runs very high and
                you may not get the right value and commitment from the hired
                team. Instilytics solves this problem by taking sweat equity in
                startups and building their products as a partner. We listen to
                your idea, advise, and provide world-class digital products on
                the web and mobile platforms.
              </li>
            </div>
          </Col>
        </Row>

        <Row className="mx-0  mt-5">
          <Col xs={12} lg={6} className="px-0 mt-4 only-mob">
            <div className="py-4 ">
              <img
                src={s2}
                style={{
                  width: "100%",
                  height: "350px",
                  zIndex: 1,
                  position: "relative",
                }}
                alt=""
              />
            </div>
          </Col>
          <Col xs={12} lg={6} className="px-0 mt-4">
            <div className="padding-side py-5">
              <li className="mt-4" style={{ fontSize: "2rem" }}>
                What we
              </li>
              <li
                className="sub-title "
                style={{
                  color: "#3152B5",
                  fontSize: "3rem",
                  lineHeight: "3rem",
                }}
              >
                Offer?
              </li>
              <li className="mt-4">
                At Instilytics, We listen to your idea, advise, and provide
                world-class digital products on the web and mobile platforms.
              </li>
            </div>
          </Col>
          <Col xs={12} lg={6} className="px-0 mt-4 only-web">
            <div className="py-4 ">
              <img
                src={s2}
                style={{
                  width: "100%",
                  height: "350px",
                  zIndex: 1,
                  position: "relative",
                }}
                alt=""
              />
            </div>
          </Col>
        </Row>

        <Row className="mx-0  mt-5">
          <Col xs={12} lg={6} className="px-0 mt-4">
            <div className=" py-4">
              <img
                src={s3}
                style={{
                  width: "100%",
                  height: "350px",
                  zIndex: 1,
                  position: "relative",
                }}
                alt=""
              />
            </div>
          </Col>
          <Col xs={12} lg={6} className="px-0 mt-4">
            <div className="padding-side py-5">
              <li
                className="sub-title mt-4"
                style={{ color: "#3152B5", fontSize: "3rem" }}
              >
                Our expections
              </li>
              <li
                className=""
                style={{ fontSize: "2rem", lineHeight: "2.2rem" }}
              >
                from a startup
              </li>
              <li className="mt-4">
                We invest our resources and expertise in our partners, so we
                look for founders with who are fully committed to the success of
                their ventures. As software experts, we ensure the solution we
                craft for your business is best-in-class. We look for Startups
                whose teams and founders are similar experts in their respective
                fields.
              </li>
            </div>
          </Col>
        </Row>
      </div>
      <KnowMore name="To know more" />

      <div className="padding-side">
        <div
          style={{
            width: "100%",
            height: "2px",
            backgroundColor: "#FBFBFB",
          }}
        ></div>
      </div>
      <Footer />
    </>
  );
};

export default Startup;
