import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Header from "../Header";
import block9 from "../../assets/9thblock.png";
import indiplanes from "../../assets/indiplanes-box.png";
import dfinkins from "../../assets/dfinkins-box.png";
import laptop from "../../assets/laptop.png";
import mobile from "../../assets/mobile.png";
import indiplane from "../../assets/indiplane.png";
import Footer from "../Footer";
import { Parallax, Background } from "react-parallax";

const Product = () => {
  return (
    <>
      <Header />
      <div className="header-marg">
        <Parallax strength={500} style={{ height: "100vh" }}>
          <Background className="enterprise-bg product-bg">
            <div className="">
              <li className="enterprise" style={{ color: "#fff" }}>
                PRODUCTS
              </li>
            </div>
          </Background>
        </Parallax>

        <Row className="mx-0">
          <Col xs={12} lg={6} className="px-0">
            <div
              className="padding-side"
              style={{ backgroundColor: "#E2F2F4" }}
            >
              <img
                src={laptop}
                style={{ maxHeight: "400px", padding: "3rem 0" }}
                alt=""
              />
            </div>
          </Col>
          <Col xs={12} lg={6} className="px-0">
            <div className="home-line-box ">
              <div className="product-line product-line1"></div>
              <div className="product-line product-line2"></div>
              <div className="product-line product-line3"></div>
            </div>
            <div className="padding-side py-5">
              <img src={block9} alt="" />
              <li
                style={{
                  color: "#00AEBC",
                  fontSize: "1.2rem",
                  fontWeight: "800",
                  margin: "10px 0",
                }}
              >
                Edge
              </li>
              <li className="mt-2">
                The idea is to develop a product which allows NGOs to run
                effectively and manage their programs, to have stronger
                collaboration, and increase transparency of CSR between the
                donors and NGOs.
              </li>
            </div>
          </Col>
        </Row>

        <Row className="mx-0 ">
          <Col xs={12} lg={6} className="px-0 only-mob">
            <div
              className="padding-side"
              style={{
                backgroundColor: "#DEE5F2",
                paddingTop: "4rem",
                paddingBottom: "4rem",
              }}
            >
              <img
                src={mobile}
                style={{ maxHeight: "400px", maxWidth: "400px" }}
                alt=""
              />
            </div>
          </Col>
          <Col xs={12} lg={6} className="px-0">
            <div className="padding-side py-5">
              <img src={block9} alt="" />
              <li
                style={{
                  color: "#EF973F",
                  fontSize: "1.2rem",
                  fontWeight: "800",
                  margin: "10px 0",
                }}
              >
                Hub
              </li>
              <li className="mt-2">
                9thBlock Hub is a new age platform which is defining fundraising
                in a different and much more collaborative way where we allow
                people to create their own networks with donors and
                beneficiaries and help a much deeper collaboration to drive
                funding.
              </li>
            </div>
            <div className="home-line-box">
              <div className="product-line product-line4"></div>
              <div className="product-line product-line5"></div>
              <div className="product-line product-line6"></div>
            </div>
          </Col>
          <Col xs={12} lg={6} className="px-0 only-web">
            <div
              className="padding-side"
              style={{
                backgroundColor: "#DEE5F2",
              }}
            >
              <img
                src={mobile}
                style={{ maxHeight: "400px", maxWidth: "500px" }}
                alt=""
              />
            </div>
          </Col>
        </Row>

        <Row className="mx-0 ">
          <Col xs={12} lg={6} className="px-0">
            <div
              className=""
              style={{
                backgroundColor: "#FFF2D8",
                zIndex: "1",
                position: "relative",
              }}
            >
              <img src={indiplane} style={{ maxHeight: "400px" }} alt="" />
            </div>
          </Col>
          <Col xs={12} lg={6} className="px-0">
            <div className="home-line-box ">
              <div className="product-line product-line1"></div>
              <div className="product-line product-line2"></div>
              <div className="product-line product-line3"></div>
            </div>
            <div className="padding-side py-5">
              <img src={indiplanes} alt="" />
              <li className="mt-4">
                The company believes in digital transformation of aviation
                industry by supporting operators & providing a customized
                solution. Our aim is to provide an effortless and enjoyable
                experience with features that automate complex tasks.
              </li>
            </div>
          </Col>
        </Row>

        <Row className="mx-0">
          <Col xs={12} lg={6} className="px-0 only-mob">
            <div
              className="padding-side py-5"
              style={{ backgroundColor: "#EBF3F9" }}
            >
              <img
                src={laptop}
                style={{ maxHeight: "400px", maxWidth: "400px" }}
                alt=""
              />
            </div>
          </Col>
          <Col xs={12} lg={6} className="px-0">
            <div className="padding-side py-5">
              <img src={dfinkins} style={{ maxWidth: "300px" }} alt="" />
              <li className="mt-4">
                Software tools, which are a crucial part of computers have
                changed the world in many huge ways, making our lives better and
                fulfilling.Financial planning made easy with DFinKins services
                which is powered by AI and which provides effective money
                management solutions
              </li>
            </div>
            <div className="home-line-box">
              <div className="product-line product-line4"></div>
              <div className="product-line product-line5"></div>
              <div className="product-line product-line6"></div>
            </div>
          </Col>
          <Col xs={12} lg={6} className="px-0 only-web">
            <div
              className="padding-side py-5"
              style={{ backgroundColor: "#EBF3F9" }}
            >
              <img
                src={laptop}
                style={{ maxHeight: "400px", maxWidth: "400px" }}
                alt=""
              />
            </div>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default Product;
