import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Home from "./components/home/Home";
import Enterprise from "./components/enterprise/Enterprise";
import Startup from "./components/startup/Startup";
import Product from "./components/product/Product";
import Contact from "./components/contact/Contact";
import Career from "./components/career/Career";

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/enterprise" component={Enterprise} />
          <Route path="/startup" component={Startup} />
          <Route path="/product" component={Product} />
          <Route path="/contact" component={Contact} />
          <Route path="/career" component={Career} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;
