import React from "react";
import { Col, Row } from "react-bootstrap";

const KnowMore = (props) => {
  return (
    <div className="">
      <div className="know-more-bg padding-side py-4">
        <li className="main-title text-center" style={{ color: "#fff" }}>
          {props.name}
        </li>

        <form
          action="https://docs.google.com/forms/d/e/1FAIpQLSdS6pB67IOqc44eaNZZMEVEBU-zYoXi0tjb_tzmuJbfTigeKg/formResponse"
          method="POST"
        >
          <Row className="mx-0">
            <Col xs={12} lg={6}>
              <div className="mt-4">
                <input
                  type="text"
                  name="entry.794984081"
                  placeholder="Full name"
                  className="input w-100"
                />
              </div>
            </Col>
            <Col xs={12} lg={6}>
              <div className="mt-4">
                <input
                  type="text"
                  name="entry.750176804"
                  placeholder="Email ID"
                  className="input w-100"
                />
              </div>
            </Col>
            <Col>
              <div className="mt-4">
                <textarea
                  name="entry.1524980224"
                  className="input w-100"
                  style={{ height: "100px" }}
                  placeholder="How can we help you?"
                ></textarea>
              </div>
            </Col>
          </Row>

          <div className="text-center mt-4">
            <button className="submit-btn">Contact</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default KnowMore;
