import React from "react";
import { Col, Row } from "react-bootstrap";
import Header from "../Header";
import e1 from "../../assets/e1.png";
import e2 from "../../assets/e2.png";
import e3 from "../../assets/e3.png";
import e4 from "../../assets/e4.png";
import e5 from "../../assets/e5.png";
import e6 from "../../assets/e6.png";
import ei1 from "../../assets/ei1.png";
import ei2 from "../../assets/ei2.png";
import ei3 from "../../assets/ei3.png";
import ei4 from "../../assets/ei4.png";
import ei5 from "../../assets/ei5.png";
import ei6 from "../../assets/ei6.png";
import Footer from "../Footer";
import { Parallax, Background } from "react-parallax";

const Enterprise = () => {
  return (
    <>
      <Header />
      <div className="header-marg">
        <Parallax strength={500} style={{ height: "100vh" }}>
          <Background className="enterprise-bg">
            <div className="">
              <li className="enterprise" style={{ color: "#fff" }}>
                ENTERPRISE
              </li>
            </div>
          </Background>
        </Parallax>

        <div className="">
          <Row className="mx-0 enterprise-card">
            <div className="home-line-box only-web">
              <div className="home-line home-line7"></div>
              <div className="home-line home-line8"></div>
              <div className="home-line home-line9"></div>
            </div>

            <Col xs={12} lg={6} className="px-0 only-mob">
              <img src={e1} style={{ width: "100%", height: "100%" }} alt="" />
            </Col>
            <Col xs={12} lg={6} className="px-0 d-flex align-items-center">
              <div className="padding-side py-4">
                <img
                  src={ei1}
                  width="40px"
                  height="50px"
                  className="mx-2"
                  alt=""
                />
                <li className="sub-title px-2" style={{ maxWidth: "400px" }}>
                  <span>Product</span>&nbsp;
                  <span style={{ color: "#FFB119" }}>innovation</span>
                </li>
                <li
                  className="px-2 mt-2"
                  style={{ color: "#373F41", maxWidth: "400px" }}
                >
                  {/* Software tools, which are a crucial part of computers have
                  changed the world in many huge ways, making our lives better
                  and fulfilling. Here are seven ways in which software
                  development has positively impacted the */}
                  Software tools, which are a crucial part of computers have
                  changed the world in many huge ways, making our lives better
                  and fulfilling. Product innovation involves creating new
                  products or improved versions of existing products that
                  increase their uses.
                </li>
              </div>

              {/* <div className="home-line-box only-web">
                <div className="home-line home-line10"></div>
                <div className="home-line home-line11"></div>
                <div className="home-line home-line12"></div>
              </div> */}
            </Col>

            <Col xs={12} lg={6} className="px-0 only-web">
              <img
                src={e1}
                style={{
                  width: "100%",
                  height: "100%",
                  zIndex: "1",
                  position: "relative",
                }}
                alt=""
              />
            </Col>
          </Row>
        </div>

        <div className="margin-top">
          <Row className="mx-0 enterprise-card">
            <Col xs={12} lg={6} className="px-0" style={{ height: "100%" }}>
              <img src={e2} style={{ width: "100%", height: "100%" }} alt="" />
            </Col>
            <Col xs={12} lg={6} className="px-0 ">
              <div
                className="padding-side d-flex align-items-center py-4"
                style={{
                  backgroundColor: "#F1F1F1",
                  width: "100%",
                  height: "100%",
                }}
              >
                <div>
                  <img
                    src={ei2}
                    width="40px"
                    height="50px"
                    className="mx-2"
                    alt=""
                  />
                  <li className="sub-title px-2" style={{ maxWidth: "400px" }}>
                    <span>Data &</span>&nbsp;
                    <span style={{ color: "#FFB119" }}>
                      Intelligence Consulting
                    </span>
                  </li>
                  <li
                    className="px-2 mt-2"
                    style={{ color: "#373F41", maxWidth: "400px" }}
                  >
                    {/* Behind every business, there is tons and tons of data.
                    However, this “raw” data is essentially useless until it is
                    analyzed and grouped into categories. */}
                    Behind every business, there is tons and tons of data.
                    However, this “raw” data is essentially useless until it is
                    analyzed and grouped into categories. At Instilytics, we
                    analyze raw data to to make meaningful inferences.
                  </li>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="margin-top">
          <Row className="mx-0 enterprise-card">
            <Col
              xs={12}
              lg={6}
              className="px-0 only-mob"
              style={{ height: "100%" }}
            >
              <img src={e3} style={{ width: "100%", height: "100%" }} alt="" />
            </Col>
            <Col xs={12} lg={6} className="px-0 d-flex align-items-center">
              <div className="home-line-box only-web">
                <div className="home-line home-line10"></div>
                <div className="home-line home-line11"></div>
                <div className="home-line home-line12"></div>
              </div>
              <div className="padding-side py-4">
                <img
                  src={ei3}
                  width="50px"
                  height="50px"
                  className="mx-2"
                  alt=""
                />
                <li className="sub-title px-2" style={{ maxWidth: "400px" }}>
                  <span>Conversational Intelligence &</span>&nbsp;
                  <span style={{ color: "#FFB119" }}>Analytics</span>
                </li>
                <li
                  className="px-2 mt-2"
                  style={{ color: "#373F41", maxWidth: "400px" }}
                >
                  {/* Powerful solutions and the capabilities of Artificial
                  Intelligence are developing very rapidly, and AI systems have
                  the ability to understand human emotions and respond to them. */}
                  Powerful solutions and the capabilities of Artificial
                  Intelligence are developing very rapidly, and AI systems have
                  the ability to understand human emotions and respond to them.
                </li>
              </div>
            </Col>
            <Col
              xs={12}
              lg={6}
              className="px-0 only-web"
              style={{ height: "100%" }}
            >
              <img src={e3} style={{ width: "100%", height: "100%" }} alt="" />
            </Col>
          </Row>
        </div>

        <div className="margin-top">
          <Row className="mx-0 enterprise-card">
            <Col xs={12} lg={6} className="px-0" style={{ height: "100%" }}>
              <div style={{ backgroundColor: "#f1f1f1", height: "100%" }}>
                <img
                  src={e4}
                  style={{ width: "100%", height: "100%" }}
                  alt=""
                />
              </div>
            </Col>
            <Col xs={12} lg={6} className="px-0">
              <div
                className="padding-side d-flex align-items-center py-4"
                style={{
                  backgroundColor: "#F1F1F1",
                  width: "100%",
                  height: "100%",
                }}
              >
                <div>
                  <img
                    src={ei4}
                    width="50px"
                    height="50px"
                    className="mx-2"
                    alt=""
                  />
                  <li className="sub-title px-2" style={{ maxWidth: "400px" }}>
                    <span>Enterprise</span>&nbsp;
                    <span style={{ color: "#FFB119" }}>IT Automations</span>
                  </li>
                  <li
                    className="px-2 mt-2"
                    style={{ color: "#373F41", maxWidth: "400px" }}
                  >
                    {/* Business leaders and owners utilizing a powerful ERP
                    software can streamline and automate difficult back-office
                    jobs. */}
                    Business leaders and owners utilizing a powerful ERP
                    software can streamline and automate difficult back-office
                    jobs.
                  </li>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="margin-top">
          <Row className="mx-0 enterprise-card">
            <Col
              xs={12}
              lg={6}
              className="px-0 only-mob"
              style={{ height: "100%" }}
            >
              <img src={e5} style={{ width: "100%", height: "100%" }} alt="" />
            </Col>
            <Col xs={12} lg={6} className="px-0 d-flex align-items-center">
              <div className="padding-side py-4">
                <img
                  src={ei5}
                  width="60px"
                  height="50px"
                  className="mx-2"
                  alt=""
                />
                <li className="sub-title px-2" style={{ maxWidth: "400px" }}>
                  <span>Cloud Consulting and</span>&nbsp;
                  <span style={{ color: "#FFB119" }}>Management</span>
                </li>
                <li
                  className="px-2 mt-2"
                  style={{ color: "#373F41", maxWidth: "400px" }}
                >
                  {/* Cloud computing has the potential to generate a series of
                  disruptions that will ripple out from the tech industry and
                  ultimately transform many industries around the world */}
                  Cloud computing has the potential to generate a series of
                  disruptions that will ripple out from the tech industry and
                  ultimately transform many industries around the world.
                </li>
              </div>
            </Col>
            <Col
              xs={12}
              lg={6}
              className="px-0 only-web"
              style={{ height: "100%" }}
            >
              <img src={e5} style={{ width: "100%", height: "100%" }} alt="" />
            </Col>
          </Row>
        </div>

        <div className="margin-top">
          <Row className="mx-0 enterprise-card">
            <Col xs={12} lg={6} className="px-0" style={{ height: "100%" }}>
              <div style={{ backgroundColor: "#f1f1f1", height: "100%" }}>
                <img
                  src={e6}
                  style={{ width: "100%", height: "100%" }}
                  alt=""
                />
              </div>
            </Col>
            <Col xs={12} lg={6} className="px-0">
              <div
                className="padding-side d-flex align-items-center py-4"
                style={{
                  backgroundColor: "#F1F1F1",
                  width: "100%",
                  height: "100%",
                }}
              >
                <div>
                  <img
                    src={ei6}
                    width="40px"
                    height="50px"
                    className="mx-2"
                    alt=""
                  />
                  <li className="sub-title px-2" style={{ maxWidth: "400px" }}>
                    <span>Digital</span>&nbsp;
                    <span style={{ color: "#FFB119" }}>Security</span>
                  </li>
                  <li
                    className="px-2 mt-2"
                    style={{ color: "#373F41", maxWidth: "400px" }}
                  >
                    {/* Data encryption is a basic form of cyber security that many
                    companies had ignored until recently. However, more and more
                    companies are implementing enterprise encryption strategies
                    in an effort to protect essential data from being accessed
                    by third parties. */}
                    Data encryption is a basic form of cyber security that many
                    companies had ignored until recently. However, more and more
                    companies are implementing enterprise encryption strategies
                    in an effort to protect essential data from being accessed
                    by third parties.
                  </li>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Enterprise;
