import React from "react";
import { Col, Row } from "react-bootstrap";
import logo from "../assets/footer-logo.png";
import location from "../assets/location-icon.png";
import phone from "../assets/phone-icon.png";
import mail from "../assets/mail-icon.png";
import { LocationOn, Mail, Phone } from "@material-ui/icons";

const Footer = () => {
  return (
    <div>
      <div className="footer-bg">
        <Row className="mx-0">
          <Col xs={12} lg={3}>
            <div>
              <img src={logo} alt="" width="100%" />
              <li className="consulting-llp">Consulting llp</li>
            </div>
          </Col>
          <Col xs={12} lg={3}>
            <div className="d-flex align-items-center mt-4">
              {/* <img src={location} width="25px" height="30px" alt="" /> */}
              <LocationOn style={{ color: "#ffb119" }} />
              <div style={{ marginLeft: "10px" }}>
                <li style={{ color: "#fff" }}>Ansal Forte, Hosur Road</li>
                <li style={{ color: "#fff" }}>Bangalore - 560068</li>
              </div>
            </div>
          </Col>
          <Col xs={12} lg={3}>
            <div className="d-flex align-items-center mt-4">
              {/* <img src={phone} width="25px" height="30px" alt="" /> */}
              <Phone style={{ color: "#ffb119" }} />
              <div style={{ marginLeft: "10px" }}>
                <li style={{ color: "#fff" }}>+91 99805 89868</li>
              </div>
            </div>
            <div className="d-flex align-items-center mt-2">
              {/* <img src={phone} width="25px" height="30px" alt="" /> */}
              <Phone style={{ color: "#ffb119" }} />
              <div style={{ marginLeft: "10px" }}>
                <li style={{ color: "#fff" }}>+1 (650)924-5372</li>
              </div>
            </div>
          </Col>
          <Col xs={12} lg={3}>
            <div className="d-flex align-items-center mt-4">
              {/* <img src={mail} width="25px" height="20px" alt="" /> */}
              <Mail style={{ color: "#ffb119" }} />
              <div style={{ marginLeft: "10px" }}>
                <li style={{ color: "#fff" }}>www.instilytics.com</li>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div className="text-center py-2">
        <li style={{ color: "rgba(75, 75, 75, 0.5);" }}>
          @ Copyright 2021 Instilytics All rights reserved.
        </li>
      </div>
    </div>
  );
};

export default Footer;
